import { isFedRamp } from '@atlassian/atl-context';

import { fg } from '@confluence/feature-gating';
import { isMobileUserAgent, shouldShowMobileWeb } from '@confluence/mobile-detection';
import { useSessionData, AccessStatus } from '@confluence/session-data';

import { useIsLoomBetaEnabled } from './useIsLoomBetaEnabled';
import { useLoomPermissionStatus } from './useLoomPermissionStatus';
import type { LoomEntryPointVariant } from './useLoomEntryPointVariantState';

export const useLoomEntryPointVariant = (): LoomEntryPointVariant => {
	const isInFedRamp = isFedRamp();
	const isMobile = isMobileUserAgent() || shouldShowMobileWeb();
	const isBetaEnabled = useIsLoomBetaEnabled();

	const { accessStatus, isLoggedIn } = useSessionData();
	const isLicensed =
		accessStatus == AccessStatus.LICENSED_ADMIN_ACCESS ||
		accessStatus == AccessStatus.LICENSED_USE_ACCESS;

	const skipPermsCheck =
		isInFedRamp ||
		isMobile ||
		isBetaEnabled ||
		!fg('confluence_frontend_loom_cross_sell') ||
		!isLoggedIn ||
		!isLicensed;

	const loomEntryPointVariant = useLoomPermissionStatus({
		skip: skipPermsCheck,
	});

	if (!isLoggedIn || !isLicensed) {
		return { entryPointVariant: 'DISABLED' };
	}

	if (isInFedRamp) {
		return { entryPointVariant: 'DISABLED' };
	}

	// don't enable entry points if user is on a mobile device or viewing mobile web
	if (isMobile) {
		return { entryPointVariant: 'DISABLED' };
	}

	if (isBetaEnabled) {
		return { entryPointVariant: 'BETA' };
	}

	if (!fg('confluence_frontend_loom_cross_sell')) {
		return { entryPointVariant: 'DISABLED' };
	}

	if (
		loomEntryPointVariant.entryPointVariant === 'CROSS_FLOW' &&
		fg('confluence_frontend_loom_cross_flow_ads_opt_out')
	) {
		return { entryPointVariant: 'DISABLED' };
	}

	return loomEntryPointVariant;
};
